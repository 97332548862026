<template>
  <div class="main-page pt-3">
    <main>
      <div class="container custom-container">
        <div class="row">
          <div class="col-12 col-sm-6">
            <ContentItems />
          </div>
          <div class="col-12 col-sm-6">
            <Templates />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ContentItems from "./ContentItems.vue";
import Templates from "./Templates.vue";

export default {
  name: "TranslationPool",
  components: { ContentItems, Templates },
};
</script>
