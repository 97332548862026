<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Rational</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <Form @submit="submitForm"
              :validation-schema="data.schema"
              v-slot="{ errors }">
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 mb-3">
                        <label class="form-control-label">Content</label>
                        <Field as="textarea"
                               name="content"
                               v-model="data.formData.content"
                               class="form-control form-control-sm"
                               placeholder="Rationale why you need Re-Opened"/>
                        <span class="text-danger">{{ errors.content || serverFormError.content }}</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </Form>
    </div>
</template>

<script>
import {computed, reactive, watch} from "vue";
import * as Yup from "yup";
import {Form, Field} from "vee-validate";

export default {
    name: "Reopen",
    props: {
        formData: {
            type: Object,
            required: false
        },
        formError: {
            type: Object,
            required: false,
            default: {}
        }
    },
    components: {
        Form,
        Field
    },
    emits: ['submitForm'],
    setup(props, {emit}) {
        let data = reactive({
            schema: Yup.object().shape({
                content: Yup.string().required(),
            }),
            formData: {
                content: ''
            }
        });

        /** computed properties **/
        const serverFormError = computed(() => {
            let output = {};
            Object.keys(props.formError).forEach((key) => {
                if (props.formError[key]) {
                    output[key] = props.formError[key][0]
                }
            });
            return output;
        })
        /** watch properties **/

        /** Methods **/
        const submitForm = () => {
            emit('submitForm', data.formData);
        }
        /** Methods end**/

        return {
            data,
            submitForm,
            serverFormError,
        }
    }

}
</script>

