<template>
  <section>
    <div class="row">
      <div
        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3"
      >
        <div class="table-box p-3">
          <!-- <div class="border-bottom pb-3">
            <form @submit.prevent="search">
              <div class="input-group search-box">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="translations?.search"
                  v-model="dataList.params.search"
                  name="search"
                />
                <button class="btn btn-outline-secondary" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                    ></path>
                  </svg>
                </button>
              </div>
            </form>
          </div> -->
          <data-table
            :translations="translations"
            :options="dataList.options"
            :data-set="data.dataList"
            :page-size="data.pageSize"
            @change-action="changeAction"
            @pagination="pagination"
            @limit-change="limitChange"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HelperFunction from "@/common/helpers";
import { onMounted, reactive, computed } from "vue";
import DataTable from "@/common/components/datatable";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ContentItems",
  components: { DataTable },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { data, deleteListData, getAllData, selectedCompany } =
      HelperFunction();

    const translations = computed(() => {
      return store.state.translations;
    });

    let dataList = reactive({
      params: {
        page: null,
        limit: null,
        sort: "desc",
        company_id: null,
        search: "",
      },
      options: {
        name: translations.value?.content_items || "Content Items",
        columns: [
          {
            title: translations.value?.identifier || "Identifier",
            type: "text",
            key: "identifier",
            tooltip: true,
          },
          {
            title: translations.value?.project_name || "Project Name",
            type: "link",
            key: "project_name",
            tooltip: true,
            truncate: true,
            modifier: (obj, row) => {
              return row.project_configured != 1
                ? `/projects/${row.project_id}/start-activity`
                : `/projects/${row.project_id}/project-dashboard`;
            },
          },
        ],
        showAction: true,
        action: [
          {
            title: "Translate",
            icon: "language",
            type: "translate",
            show: true,
          },
        ],
      },
    });

    /** mounted**/
    onMounted(() => {
      getData();
    });
    /** mounted end**/
    const getData = () => {
      data.setURL = vueConfig.Library.ApprovedContentItemsEndPoint;
      dataList.params.company_id = selectedCompany.value.id
        ? selectedCompany.value.id
        : null;
      getAllData(data.setURL, dataList.params);
    };

    const changeAction = (action, row) => {
      if (action.type === "translate") {
        router.push(`/library/content-library/${row.content_item_id}`);
      }
    };
    const pagination = (page) => {
      dataList.params.page = page;
      getData();
    };
    const limitChange = (limit) => {
      dataList.params.limit = limit;
      delete dataList.params.page;
      getData();
    };

    function search() {
      getData();
    }

    return {
      data,
      pagination,
      limitChange,
      changeAction,
      dataList,
      search,
      translations,
    };
  },
};
</script>
