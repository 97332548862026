<template>
  <section>
    <div class="row">
      <div
        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3"
      >
        <div class="table-box p-3">
          <!-- <div class="border-bottom pb-3">
            <div class="d-flex">
              <div class="flex-grow-1">
                <router-link
                  v-if="data.permission.canCreate"
                  :to="'/library/create-template'"
                  class="btn btn-primary"
                  type="button"
                >
                  {{ translations?.create_new }}
                </router-link>
              </div>
              <div class="expand-ctl">
                <a
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseProject-ctl"
                  aria-expanded="false"
                  aria-controls="collapseProject-ctl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-funnel-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div class="collapse mt-3" id="collapseProject-ctl">
              <table-filter
                :translations="translations"
                @change="getFilteredValue"
              />
            </div>
          </div> -->

          <data-table
            :translations="translations"
            :options="dataList.options"
            :data-set="data.dataList"
            :page-size="data.pageSize"
            @change-action="changeAction"
            @pagination="pagination"
            @limit-change="limitChange"
          />
        </div>
      </div>
    </div>
  </section>

  <!-- Modal -->
  <div
    class="modal fade"
    id="showModal"
    tabindex="-1"
    aria-labelledby="ReopenModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <reopen
        v-if="dataList.isReopenModalActive"
        :form-data="dataList.rowData"
        @submit-form="submitReopenFrom"
      />
    </div>
  </div>
  <!-- /Modal -->
</template>

<script>
import HelperFunction from "@/common/helpers";
import { onMounted, reactive, watch, computed } from "vue";
import DataTable from "@/common/components/datatable";
import { useRoute, useRouter } from "vue-router";
import Reopen from "@/views/auth/library/template/ReopenModal";
import TableFilter from "@/common/components/table-filter/TableFilter";
import { useStore } from "vuex";
import {
  createPermission,
  deletePermission,
  statusPermission,
  updatePermission,
} from "@/common/helpers/permission.helper";

export default {
  name: "Templates",
  components: { TableFilter, Reopen, DataTable },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const {
      data,
      deleteListData,
      getAllData,
      storeFormData,
      selectedCompany,
      openModal,
    } = HelperFunction();

    const translations = computed(() => {
      return store.state.translations;
    });

    let dataList = reactive({
      isReopenModalActive: false,
      params: {
        page: null,
        limit: null,
        sort: "desc",
        company_id: null,
        search: "",
      },
      rowData: {},
      options: {
        name: translations.value?.templates || "Templates",
        columns: [
          {
            title: translations.value?.name || "Name",
            type: "text",
            key: "name",
            tooltip: true,
          },
        ],
        showAction: true,
        action: [
          {
            title: "Translate",
            icon: "language",
            type: "translate",
            show: true,
          },
        ],
      },
    });

    onMounted(() => {
      getData();
      $("#showModal").on("hidden.bs.modal", (e) => {
        dataList.isReopenModalActive = false;
        dataList.rowData = {};
      });
    });

    const modalOpen = () => {
      dataList.isReopenModalActive = true;
      openModal("showModal");
    };

    const getData = async () => {
      data.setURL = vueConfig.Library.ApprovedTemplatesEndPoint;
      dataList.params.company_id = selectedCompany.value.id
        ? selectedCompany.value.id
        : null;
      await getAllData(data.setURL, dataList.params);
      await store.dispatch("setTemplateContentItems", []);
    };

    const submitReopenFrom = (formData) => {
      const action = {
        url: `${vueConfig.Library.TemplatesEndPoint}/${dataList.rowData.id}/reopen`,
      };
      storeFormData(action, formData)
        .then((response) => {
          router.push(`/library/update-template/${response.data.data.id}`);
          $("#showModal").modal("hide");
        })
        .catch((error) => {
          if (error.response) {
            dataList.formError = error.response.data.errors;
          }
        });
    };

    /** data table emits**/
    const changeAction = (action, row) => {
      dataList.rowData = row;
      if (action.type === "translate") {
        router.push(`/library/update-template/${row.id}`);
      }
    };
    const pagination = (page) => {
      dataList.params.page = page;
      getData();
    };
    const limitChange = (limit) => {
      dataList.params.limit = limit;
      delete dataList.params.page;
      getData();
    };

    /** data table emits**/

    function getFilteredValue(params) {
      dataList.params = { ...dataList.params, ...params };
      getData();
    }

    return {
      data,
      dataList,
      limitChange,
      pagination,
      changeAction,
      submitReopenFrom,
      getFilteredValue,
      translations,
    };
  },
};
</script>
